import { ReactComponent as Check } from "../../../assets/images/icon/check.svg";
import { Modal } from "antd";
import "./index.css"
import { TextBtn } from "../../";

interface IProps {
    isOpen: boolean;
    setIsOpen: Function;
    dataList: IBottomSheet[];
    value: string | number;
    setValue: Function;
    isShowLabel?: boolean;
    title?: string | React.ReactNode;
    styles?: { data?: React.CSSProperties, content?: React.CSSProperties, textBtnStyle?: React.CSSProperties, textBtnStyles?: { container?: React.CSSProperties } };
}

const BottomSheet = ({ title, isOpen, setIsOpen, dataList, setValue, value, isShowLabel, styles }: IProps) => {
    const contentHeight = (dataList.length * 56) + 60 + (title ? 39 : 0);
    const minWidth = window.innerWidth <= 480 ? '100vw' : '480px';
    const marginInline = window.innerWidth <= 480 ? '0' : (window.innerWidth - 480) / 2;
    return <>
        {isShowLabel && <TextBtn style={styles?.textBtnStyle} styles={styles?.textBtnStyles} rightIcon='arrowDown' onClick={() => setIsOpen(true)}>{dataList.find((data) => data.value === value)?.label}</TextBtn>}
        <Modal transitionName="custom-modal"
            style={{ top: `calc(100vh - ${contentHeight}px)`, padding: 0, margin: 0 }}
            styles={{ content: { minWidth: minWidth, height: `${contentHeight}px`, overflow: "scroll", borderRadius: "5% 5% 0 0", padding: "var(--space20) var(--space20) var(--space40)", marginInline: marginInline + 'px', ...styles?.content } }}
            open={isOpen} closeIcon={null} footer={null} onCancel={() => setIsOpen(false)}>
            {title && <div className="title/t1" style={{ color: '#111111', marginBottom: 'var(--space20)' }}>{title}</div>}
            {dataList.map(data => <div style={{ height: '32px', display: 'flex', alignItems: 'center', justifyContent: "space-between", padding: 'var(--space12) 0px var(--space12) var(--space12)', cursor: 'pointer', ...styles?.data }}
                key={data.id}
                onClick={() => { setValue(data.value); setIsOpen(false); }}>
                <div className={data.value === value ? 'sub-title/st3' : 'body/b3'}>
                    {data.label}
                </div>
                {data.value === value && <Check width={16} height={16} stroke={'#000000'} style={{ padding: 'var(--space8)' }} />}
            </div>
            )}
        </Modal>
    </>
}

export { BottomSheet };


