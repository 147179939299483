import { Button, Modal } from "antd"
import { ReactComponent as Close } from "../../../assets/images/icon/close.svg"
import { useMyContext } from "../../../entities/context";
import { useState } from "react";
import { BottomSheet } from "../bottomSheet";
import { TopBar } from "../../../widgets";


interface IProps {
    openModal: boolean;
    setOpenModal: Function;
    onOkClick: Function
}
const TicketCancleModal = ({ openModal, setOpenModal, onOkClick }: IProps) => {
    const { topHeight } = useMyContext();

    return <>
        <Modal
            style={{ top: 0, margin: 0, padding: 0 }}
            styles={{ content: { width: '100vw', padding: '0 0 40px 0', borderRadius: 0 }, footer: { paddingInline: '20px' } }}
            open={openModal}
            closeIcon={false}
            footer={
                <Button size="large"
                    className='sub-title/st3'
                    style={{ width: '100%', height: '51px', backgroundColor: 'black', color: 'white' }}
                    onClick={() => { onOkClick(); setOpenModal(false); }}>
                    동의합니다
                </Button>}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
        >
            <TopBar type='closeIcon' leftIconOnClick={() => setOpenModal(false)} title={'파지티브호텔 요가 이용 약관'} styles={{ title: { padding: '13px 0', lineHeight: '30px' } }} />
            <div className="body/b2" style={{ padding: '0 20px', color: "#555555" }}>
                <div>
                    <div className="sub-title/st2">1. 목적</div>
                    <div>파지티브호텔 요가와 게스트 상호 간 약관을 정함으로써</div>
                    <div>효율적인 운영 및 게스트 심신 단련을 목적을 명확히 하는데 있다.</div>
                    <br />
                    <div className="sub-title/st2">2. 멤버의 자격</div>
                    <div>① 멤버란, 파지티브호텔 요가가 정하는 약관에 동의하며 게스트 등록을 완료한 자를 말한다.</div>
                    <div>② 회원 상호 간 위화감 조성, 질병, 풍기문란, 시설이용 규정 위반 등 시설 운영에 지장을 초래한 게스트는 그 자격이 제한되거나 박탈될 수 있다.</div>
                    <br />
                    <div className="sub-title/st2">3. 시설의 이용</div>
                    <div>① 멤버는 규정 클래스 시간을 준수할 의무를 가지며 클래스 효율적인 이용 권리를 위하여 클래스 시작 후 10분이 경과할 경우 입장이 불가함을 원칙으로 하며, 클래스의 성격, 상황에 따라 10분이 경과하였음에도 참여가 가능하다고 판단될 경우 커뮤니티 매니저의 도움을 받아 클래스 도중 참여하도록 한다.</div>
                    <div>② 클래스 중 다른 게스트들의 수련에 영향을 주는 휴대폰 사용, 소음발생, 음식물 섭취 등의 행위는 금지된다.</div>
                    <div>③ 센터의 사정에 의하여 정규 프로그램 및 서비스는 변경될 수 있다.</div>
                    <br />
                    <div className="sub-title/st2">4. 병력사항</div>
                    <div>① 멤버는 현재 본인이 운동을 할 수 있는 적절한 육체적, 정신적 상태에 있으며 수련을 받을 수 없는 의학적 이유나 건강쇠약 또는 질병을 가지고 있지 않음을 사전 확인할 의무를 가진다. 또한 파지티브호텔 요가의 강사, 웰니스 코치는 멤버에게 어떠한 육체적 상태/능력과 더불어 의학적 충고를 하지 않는다.</div>
                    <div>② 멤버는 등록 전 건강상 의학적 우려나 궁금증이 있을 경우 반드시 전문의와 사전에 상담하여야 한다.</div>
                    <br />
                    <div className="sub-title/st2">5. 사고로 인한 책임과 손해배상</div>
                    <div>① 멤버의 센터 이용 수칙 위반, 고의 또는 부주의, 과실 등으로 인하여 ‘파지티브호텔 요가’ 귀속의 자산(센터의 시설물, 대여 물품 등)이 훼손/파손되었을 경우 손해에 대한 책임을 지어 배상하여야 한다.</div>
                    <div>② 시설 등에 의해 멤버에게 신체상 피해가 발생한 경우 파지티브호텔 요가는 그 손해를 배상하여야 하나 만일, 불가항력(천재지변 등)한 사유나 게스트의 고의 또는 과실이 원인일 경우 그 손해를 배상할 책임이 없다.</div>
                    <div>단, 멤버와 파지티브호텔 요가의 과실이 모두 있다고 판단되는 경우, 과실 비율에 따라 손해배상액을 정한다.</div>
                    <br />
                    <div className="sub-title/st2">6. 면책조항</div>
                    <div>① 천재지변, 전염병 등 통제 불가능한 사유로 시설 등 이용이 불가능하게 된 경우 파지티브호텔 요가는 그로 인한 손해배상책임에서 면책된다.</div>
                    <div>② 단, 위의 사유로 멤버는 파지티브호텔 요가에 계약 해지를 요구할 수 있으며 파지티브호텔 요가는 게스트에게 환불정책에 따라 환불을 진행할 의무를 가진다.</div>
                    <br />
                    <div className="sub-title/st2">7. 물품의 보관</div>
                    <div>① 소지품 및 귀중품의 보관은 게스트 본인에게 관리책임이 있으며, 파지티브호텔 요가 웰니스 코치 등을 포함한 모든 소속직원은 게스트의 소지품 및 귀중품을 보관할 의무가 없다.</div>
                    <div>② 멤버 본인의 부주의/과실로 인하여 분실된 귀중품 또는 소지품은 파지티브호텔 요가에서 책임지지 않는다.</div>
                    <div>③ 게스트 등록 기간 내 개인 요가매트 보관이 가능하며, 만료일 기준 15일 이후에는 자동 폐기됨에 동의한다. (보상의무없음)</div>
                    <br />
                    <div className="sub-title/st2">8. 환불</div>
                    <div>횟수권 이용기간의 절반 이상 기간이 지났을 경우는 환불이 불가함을 원칙으로 한다. (4회권(30일중 15일), 10회권(50일중 25일), 20회권(80일중 40일), 40회권(140일중 70일), 60회권(200일중 100일))</div>
                    <br />
                    <div>① 거주지의 이동, 장기간의 해외 파견, 갑작스러운 질병으로 인해 더 이상의 원활한 클래스 이용이 불가한 경우를 제외하고는 환불은 불가능하다. 위의 해당 사유에 포함되는 경우, 해당 사유에 대한 증빙을 필히 하는 것을 원칙으로 한다. 위의 경우에 해당될 때는 결제금액의 10% 위약금이 발생하며, 1회 이상 사용한 경우 단가 × 사용 횟수 금액이 추가 차감되어 환불된다.</div>
                    <div>단, 회원권 발급 후 지급 된 사은품이 있는 경우 사은품을 반환하여야 하며, 반환이 어려울 경우 정가 기준 별도 청구된다.</div>
                    <div>또한 프로모션이나 이벤트 기간 내에 등록한 회원권은 정상가를 적용하여 환불된다.</div>
                    <div>② 양도는 결제 횟수권 당 1회만 가능하며, 양도비는 3만원이다. 단, 재양도는 불가하다</div>
                    <br />
                    <div className="sub-title/st2">9. 기본 정책</div>
                    <div>① 기간 연장 및 중단은 불가하다.</div>
                    <div>※ 단, 명확한 연장 사유와 함께 증빙자료 제출이 가능한 경우에 한해 아래의 기준에 따라 회원권 당 1회만 연장이 가능하다.</div>
                    <div>10회권 : 최대 1주(7일) 연장 가능</div>
                    <div>20회권 : 최대 2주(14일) 연장 가능</div>
                    <div>40회권 : 최대 3주(21일) 연장 가능</div>
                    <div>60회권 : 최대 4주(28일) 연장 가능</div>
                    <div>② 멤버 등록 기간 내 이벤트 등으로 제공받은 횟수권은 환불 또는 이월되지 않는다.</div>
                    <div>③ 클래스 시작 3시간 전까지 예약 취소가 가능하며, 이후 시간 취소 시 횟수권은 차감된다.</div>
                    <div>④ 클래스 시작 후 10분이 경과할 경우 입장이 불가하며 횟수권은 차감된다.</div>
                </div>
                <br />
            </div>
        </Modal>
    </>
}

export { TicketCancleModal }