import { Modal } from "antd";
import { useState } from "react";
import { ReactComponent as Close } from '../../assets/images/icon/close.svg';
import dayjs from "dayjs";
import { SolidBtn } from "../../shared";
import { cancelReservationByMemberIdAndReservationId } from "../../service";

interface IProps {
    text: string;
    myReservation: IReservationMyWebResponseV2;
    isRequestCancel: boolean;
    setIsRequestCancel: Function;
    requestMyReservationByToken: Function;
    requestMyTicketByToken: Function;
}
const CancelReservationWithModal = ({ text, myReservation, isRequestCancel, setIsRequestCancel, requestMyReservationByToken, requestMyTicketByToken }: IProps) => {
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
    const [isCancelDisableModalOpen, setIsCancelDisableModalOpen] = useState<boolean>(false);
    const [isCancelCompleteModalOpen, setIsCancelCompleteModalOpen] = useState<boolean>(false);
    const clickCancelReservation = async () => {
        if (dayjs().isAfter(dayjs(myReservation.wellnessLectureStartDateTime).add(-3, 'h'))) {
            setIsCancelModalOpen(false);
            setIsCancelDisableModalOpen(true);
            return;
        }

        setIsCancelModalOpen(false);
        try {
            setIsRequestCancel(true)
            const res = await cancelReservationByMemberIdAndReservationId(myReservation.reservationId)
            if (["중이라 취소 할 수 없습니다.", "3시간 이전에만 취소가 가능합니다."].some(checkString => res.data.includes(checkString))) {
                setIsCancelDisableModalOpen(true);
            } else {
                setIsCancelCompleteModalOpen(true);
                requestMyReservationByToken();
                requestMyTicketByToken()
            }
        } catch (error) {
            console.error('error', error)
        } finally {
            setIsRequestCancel(false)
        }
    }

    return <>
        <div onClick={(e) => { e.stopPropagation(); setIsCancelModalOpen(isRequestCancel ? false : true); }}>{text}</div>
        <Modal centered open={isCancelModalOpen} styles={{ content: { padding: 'var(--space16)', borderRadius: 'var(--radius4)' } }} onCancel={() => setIsCancelModalOpen(false)} footer={null} closeIcon={null}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space16)' }}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className="sub-title/st3 base/black">예약을 취소할까요?</div>
                        <Close width={16} height={16} stroke="#AEAEAE" style={{ padding: 'var(--space8)', cursor: 'pointer' }} onClick={() => setIsCancelModalOpen(false)} />
                    </div>
                    <div className="body/b2 secondary/s800">취소하려는 예약 내역을 다시 확인해 보세요.</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space12)', backgroundColor: 'var(--secondarys100)', padding: 'var(--space12)', borderRadius: 'var(--radius4)' }}>
                    <div className="sub-title/st3 base/black" style={{ textAlign: 'center' }}>{myReservation.wellnessLectureName}</div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space4)' }}>
                        <div style={{ display: "flex", gap: "var(--space8)" }}>
                            <div className="sub-title/st2 secondary/s700" style={{ width: '52px', display: 'flex', justifyContent: 'space-between' }}><div>수업</div><div>시간</div></div>
                            <div className="body/b2 secondary/s700">
                                {dayjs(myReservation.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')} - {dayjs(myReservation.wellnessLectureEndDateTime).format('HH:mm')}
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "var(--space8)" }}>
                            <div className="sub-title/st2 secondary/s700" style={{ width: '52px', display: 'flex', justifyContent: 'space-between' }}><div>코</div><div>치</div></div>
                            <div className="body/b2 secondary/s700">{myReservation.teacherName} 코치</div>
                        </div>
                        <div style={{ display: "flex", gap: "var(--space8)" }}>
                            <div className="sub-title/st2 secondary/s700" style={{ width: '52px', display: 'flex', justifyContent: 'space-between' }}><div>수업</div><div>장소</div></div>
                            <div className="body/b2 secondary/s700">{myReservation.yogaCenterName} {myReservation.wellnessLectureRoom}</div>
                        </div>
                    </div>
                </div>
                <SolidBtn type='line' icon={false} style={{ border: '1px solid var(--secondarys500)', color: 'var(--secondarys500)' }} onClick={() => clickCancelReservation()}>
                    예약 취소하기
                </SolidBtn>
            </div>
        </Modal>
        <Modal centered open={isCancelCompleteModalOpen} styles={{ content: { padding: 'var(--space20)', borderRadius: 'var(--radius4)' } }} closeIcon={null} onCancel={() => setIsCancelCompleteModalOpen(false)} footer={null}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space16)' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space8)', textAlign: 'center' }}>
                    <div className="sub-title/st4 base/black">예약 취소 완료</div>
                    <div className="body/b2 secondary/s800">취소가 완료되었습니다.</div>
                </div>
                <SolidBtn type='line' icon={false} style={{ border: '1px solid var(--secondarys500)', color: 'var(--secondarys500)' }} onClick={() => { setIsCancelCompleteModalOpen(false) }}>
                    확인
                </SolidBtn>
            </div>
        </Modal>
        <Modal centered open={isCancelDisableModalOpen} styles={{ content: { padding: 'var(--space20)', borderRadius: 'var(--radius4)' } }} closeIcon={null} onCancel={() => setIsCancelDisableModalOpen(false)} footer={null}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space16)' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space8)', textAlign: 'center' }}>
                    <div className="sub-title/st4 base/black">예약 취소 불가</div>
                    <div className="body/b2 secondary/s800">수업 시작 3시간 이전에만 취소가 가능합니다.</div>
                </div>
                <SolidBtn type='line' icon={false} style={{ border: '1px solid var(--secondarys500)', color: 'var(--secondarys500)' }} onClick={() => { setIsCancelDisableModalOpen(false) }}>
                    확인
                </SolidBtn>
            </div>
        </Modal>
    </>
}

export { CancelReservationWithModal }